import { useNavigate } from 'react-router-dom';
import Constants from '../../utils/Constants';
import MenuList from './MenuList';

function OpenPdf(pdfPath: string) {
  window.open(pdfPath, '_blank', 'noreferrer');
}

const Menu = () => {
  const navigate = useNavigate();

  return (
    <div id={Constants.MENU} className='menu'>
      <h1 className='menu_title'>Cora Hotel Info</h1>
      <div className='menu_container'>
        {MenuList.map((menuItem) => (
          <div
            className='menu_container_item'
            onClick={() => (menuItem.pdfPath ? OpenPdf(menuItem.pdfPath) : menuItem.link && navigate(menuItem.link))}
          >
            <div className='menu_container_item_image'>
              <img src={menuItem.imgPath} alt={menuItem.title} />
            </div>
            <h2>{menuItem.title}</h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
