import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Constants from '../utils/Constants';
import logo from '../assets/images/cora_logo_white.svg';
import { useEffect } from 'react';

const Layout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className='wrapper'>
      <div className='layout_logo'>
        <img
          src={logo}
          alt={`${Constants.PROPERTY_NAME} logo`}
          className='opacity_transition_low'
          onClick={() => navigate('/')}
        />
      </div>
      <Outlet />
      <h2 className='menu_subtitle text_center'>Stay in Split, stay in style, stay at Cora</h2>
    </div>
  );
};

export default Layout;
