import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Constants from './utils/Constants';
import Layout from './pages/Layout';
import Menu from './pages/menu/Menu';
import WhereToEat from './pages/whereToEat/WhereToEat';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Menu />} />
          <Route path={Constants.WHERE_TO_EAT} element={<WhereToEat />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
