import Constants from '../../utils/Constants';

const images = require.context('../../assets/images/menu', true);
const pdfs = require.context('../../assets/documents', true);

const imageList: Array<string> = images.keys().map((image: any) => images(image));
const pdfList: Array<string> = pdfs.keys().map((pdf: any) => pdfs(pdf));

interface MenuItem {
  title: string;
  imgPath: string;
  pdfPath?: string;
  link?: string;
}

const FACT_SHEET: MenuItem = {
  title: 'Fact sheet',
  imgPath: imageList.find((x) => x.includes(Constants.FACT_SHEET)) || '',
  pdfPath: pdfList.find((x) => x.includes(Constants.FACT_SHEET)) || '',
};

const TV_PROGRAMS: MenuItem = {
  title: 'TV Programs',
  imgPath: imageList.find((x) => x.includes(Constants.TV_PROGRAMS)) || '',
  pdfPath: pdfList.find((x) => x.includes(Constants.TV_PROGRAMS)) || '',
};

const ROOF_BAR: MenuItem = {
  title: 'Roof bar',
  imgPath: imageList.find((x) => x.includes(Constants.ROOF_BAR)) || '',
  pdfPath: pdfList.find((x) => x.includes(Constants.ROOF_BAR)) || '',
};

const PILLOW_MENU: MenuItem = {
  title: 'Pillow Menu',
  imgPath: imageList.find((x) => x.includes(Constants.PILLOW_MENU)) || '',
  pdfPath: pdfList.find((x) => x.includes(Constants.PILLOW_MENU)) || '',
};

const INFO_BOOK: MenuItem = {
  title: 'Info Book',
  imgPath: imageList.find((x) => x.includes(Constants.INFO_BOOK)) || '',
  pdfPath: pdfList.find((x) => x.includes(Constants.INFO_BOOK)) || '',
};

const EXCURSIONS: MenuItem = {
  title: 'Excursions ',
  imgPath: imageList.find((x) => x.includes(Constants.EXCURSIONS)) || '',
  pdfPath: pdfList.find((x) => x.includes(Constants.EXCURSIONS)) || '',
};

const MINIBAR: MenuItem = {
  title: 'Minibar ',
  imgPath: imageList.find((x) => x.includes(Constants.MINIBAR)) || '',
  pdfPath: pdfList.find((x) => x.includes(Constants.MINIBAR)) || '',
};

const BREAKFAST: MenuItem = {
  title: 'Breakfast',
  imgPath: imageList.find((x) => x.includes(Constants.BREAKFAST)) || '',
  pdfPath: pdfList.find((x) => x.includes(Constants.BREAKFAST)) || '',
};

const WHERE_TO_EAT: MenuItem = {
  title: 'Where to eat?',
  imgPath: imageList.find((x) => x.includes(Constants.WHERE_TO_EAT)) || '',
  link: Constants.WHERE_TO_EAT,
};

const MenuList: Array<MenuItem> = [
  FACT_SHEET,
  TV_PROGRAMS,
  ROOF_BAR,
  PILLOW_MENU,
  INFO_BOOK,
  EXCURSIONS,
  MINIBAR,
  BREAKFAST,
  WHERE_TO_EAT,
];

export default MenuList;
