const PROPERTY_NAME = 'cora';

const MENU = 'menu';

const FACT_SHEET = 'FACT_SHEET';
const TV_PROGRAMS = 'TV_PROGRAMS';
const ROOF_BAR = 'ROOF_BAR';
const PILLOW_MENU = 'PILLOW_MENU';
const INFO_BOOK = 'INFO_BOOK';
const EXCURSIONS = 'EXCURSIONS';
const BREAKFAST = 'BREAKFAST';
const MINIBAR = 'MINIBAR';
const WHERE_TO_EAT = 'where-to-eat';

const webLink = (address: string, imgPath: string) => {
  return (
    <a href={address} target='_blank' rel='noreferrer' className='opacity_transition_low'>
      <img src={imgPath} alt='web link logo' />
    </a>
  );
};

const Constants = {
  PROPERTY_NAME,
  MENU,
  FACT_SHEET,
  TV_PROGRAMS,
  ROOF_BAR,
  PILLOW_MENU,
  INFO_BOOK,
  EXCURSIONS,
  BREAKFAST,
  MINIBAR,
  WHERE_TO_EAT,
  webLink,
};

export default Constants;
