import Constants from '../../utils/Constants';
import { RESTAURANT, WALKING_DISTANCE, FINE_DINING, SEAFOOD_LOCALFOOD, PIZZA_BURGER } from './whereToEatList';
import tripadvisorLogo from '../../assets/images/tripadvisor.svg';
import bookingLogo from '../../assets/images/booking.svg';
import webLogo from '../../assets/images/web.svg';

const RestaurantCard = (restaurant: RESTAURANT) => {
  const Price = (price: number) => {
    const dif = 5 - price;

    return (
      <div style={{ display: 'flex' }}>
        <b>$</b> &nbsp;
        {Array.from({ length: price }, (_, index) => (
          <div className='circle_filled' key={index}></div>
        ))}
        {dif > 0 && Array.from({ length: dif }, (_, index) => <div className='circle_empty' key={index}></div>)}
      </div>
    );
  };

  return (
    <div className='eat_container_card'>
      <h2 className='title_underline_light_small'>{restaurant.name}</h2>
      <p>{restaurant.serves}</p>
      <p>{Price(restaurant.price)}</p>
      <p>
        <a href={`tel:${restaurant.contact}`} target='_blank' rel='noreferrer' className='opacity_transition_low title_underline_light_smaller'>
          {restaurant.contact}
        </a>
      </p>
      <p>
        <a href={restaurant.addressPath} target='_blank' rel='noreferrer' className='opacity_transition_low opacity_transition_low title_underline_light_smaller'>
          {restaurant.address}
        </a>
      </p>
      <div className='eat_container_card_web'>
        {restaurant.booking && Constants.webLink(restaurant.booking, bookingLogo)}
        {restaurant.web && Constants.webLink(restaurant.web, webLogo)}
        {restaurant.tripadvisor && Constants.webLink(restaurant.tripadvisor, tripadvisorLogo)}
      </div>
    </div>
  );
};

const WhereToEat = () => {
  return (
    <div id={Constants.WHERE_TO_EAT} className='eat'>
      <h1>Where To Eat?</h1>
      <h2>Cora walking distance:</h2>
      <div className='eat_container'>{WALKING_DISTANCE.map((restaurant) => RestaurantCard(restaurant))}</div>
      <h2>Fine Dining:</h2>
      <div className='eat_container'>{FINE_DINING.map((restaurant) => RestaurantCard(restaurant))}</div>
      <h2>Seafood / Localfood:</h2>
      <div className='eat_container'>{SEAFOOD_LOCALFOOD.map((restaurant) => RestaurantCard(restaurant))}</div>
      <h2>Pizza / Burger:</h2>
      <div className='eat_container'> {PIZZA_BURGER.map((restaurant) => RestaurantCard(restaurant))}</div>
    </div>
  );
};

export default WhereToEat;
